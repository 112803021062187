import axios from "axios";
import config from "../../common/config";
import { encrypt_interceptor } from "../interceptors/requestEncrypt.ts";
import { decrypt_interceptor } from "../interceptors/responseDecrypt.ts";

const instance = axios.create({
  baseURL: config.baseUrl,
  timeout: 15000,
});
// set headers content-type
// instance.headers.post["Content-Type"] = "application/json";

// ============================= request ========================
// // 添加请求拦截器
instance.interceptors.request.use(
  encrypt_interceptor,
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// ============================== response ==========================

// 开始解密的处理和返回
instance.interceptors.response.use(
  decrypt_interceptor,
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
