import urlUtils from "../../../../utils/url_utils";
import config from "../../../common/config";
import { loadJs } from "../../../../utils/utils";
import { animation } from "../../../../utils/toast_loading";
import {
  actual_effect_factory,
  add_payment_details,
} from "../../../../utils/pay_details_utils";
import { error_toast_and_event } from "../../../../utils/error_utils";
import { isSubsOffer } from "../../../../utils/payments_utils";
import { error_hook, cancle_hook } from "./hooks/checkout_error_hook";
import { create_order_hook, create_order } from "./hooks/checkout_create_hook";
import { approve_hook } from "./hooks/checkout_approve_hook";

/**
 * 开始的checkout支付，需要重定向
 */
export const pay_pal_checkout_pay = async (init_result: any, urlData: any) => {
  try {
    const { client_id, request_url, url, plan_id, offer_data } = init_result;
    //收到获取的参数，带上新的参数，进行重定向
    const queryParams: any = {
      request_url: request_url,
      ae: actual_effect_factory(init_result),
      pk: offer_data && offer_data.kind,
      ps: urlData.price,
      pc: urlData.product_count,
      tcid: client_id,
      ss: urlData.source_scheme
    };
    // 订阅类型才有plan_id
    plan_id && (queryParams.pppid = plan_id);
    // 此时需要重定向到url地址去购买请求：
    console.log("queryParams:", queryParams);

    // 重定向:
    let baseURL = url;
    if (process.env.NODE_ENV === "development") {
      // 如果是本地测试环境baseURL为本机地址,方便调试
      // baseURL = window.location.origin;
      baseURL = "http://0.0.0.0:8081/";
    }
    // 提前执行create order
    const id = await create_order(urlData, client_id, plan_id);
    queryParams.coid = id;
    // return;
    // const baseURL = window.location.origin;
    // const fullURL = window.location.href;
    // const queryString = urlUtils.getUrlQuery(fullURL);
    const plusQueryString = urlUtils.queryString(queryParams);
    // return;
    // 跳转：
    window.location.href = `${baseURL}?${plusQueryString}`;
  } catch (error) {
    return Promise.reject(error);
  }
};

const load_pay_pal_checkout_sdk = async (urlData: any) => {
  //
  try {
    const isSubs = isSubsOffer(urlData);
    const baseURL = config.PAY_PAY_SDK_URL;
    const { clientId } = urlData;
    const path = "?client-id=" + clientId + (isSubs ? "&vault=true" : "");
    const fullURL = baseURL + path;
    const loadResult = await loadJs(fullURL);
    console.log("loadSDKResult:", loadResult);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * 执行paypal button的初始化钩子的方法
 * Do not pass both createSubscription and createOrder
 * 不能同时挂载 createSubscription | createOrder
 * @param urlData
 */
const pay_pal_sdk_hooks = (urlData: any) => {
  const pay_pay_init_hooks: any = {
    onError: error_hook(urlData),
    onCancel: cancle_hook,
    onApprove: approve_hook(urlData),
  };
  const isSubs = isSubsOffer(urlData);
  isSubs
    ? (pay_pay_init_hooks.createSubscription = create_order_hook(urlData))
    : (pay_pay_init_hooks.createOrder = create_order_hook(urlData));

  // 初始化钩子配置方法
  (window as any).paypal
    .Buttons(pay_pay_init_hooks)
    .render("#paypal-button-container")
    .then(() => {
      // 隐藏最开始的动画
      animation.hide();
    });
};

/**
 * 重定向回来的checkout支付，直接显示checkout页面
 */
export const pay_pal_checkout_pay_direct = async (urlData: any) => {
  try {
    //
    console.log("pay_pal_checkout_pay_direct:", urlData);
    // show title and pic 先显示价格文案和数量等:
    add_payment_details(urlData);

    // load_sdk_js:
    await load_pay_pal_checkout_sdk(urlData);

    // start paypal hooks
    pay_pal_sdk_hooks(urlData);
  } catch (error) {
    //
    error_toast_and_event(error);
  }
};
