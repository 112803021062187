// import eco_event from "../../utils/log_event/eco_event";
import { animation } from "../../../utils/toast_loading";
import {
  error_toast_and_event,
  custom_name_error,
} from "../../../utils/error_utils";
import redirect from "../../../utils/redirect_utils";
import { RedirectMark } from "../../../config/types";

const success_pay = () => {
  //
  try {
    const fullUrl = window.location.href;
    const successPayDataString = decodeURIComponent(
      fullUrl.substring(
        fullUrl.indexOf(RedirectMark.SUCCESS_PAY) +
          RedirectMark.SUCCESS_PAY.length +
          1
      )
    );
    const successPayData = JSON.parse(successPayDataString);
    console.log("successPayData:", successPayData);

    if (successPayData.hasOwnProperty("err")) {
      // ===================== 购买失败 =====================

      // annimation :
      animation.error();

      //购买失败 后台可能不返回source_scheme 只能toast 避免回跳undefined
      if (successPayData.source_scheme) {
        setTimeout(() => {
          redirect.failBackToNative(
            successPayData.source_scheme,
            successPayData.err
          );
        }, 1200);
      }

      // 抛出错误:
      const custom_error = custom_name_error(
        successPayData.err,
        "[success_pay_data_err]"
      );
      throw custom_error;
    } else if (JSON.stringify(successPayData) == "{}") {
      // 特殊情况，后台只返回了{}
      // annimation :
      animation.success();

      //需要抛出错误:
      // 抛出错误:
      const custom_error = custom_name_error(
        "empty success pay data string",
        "[success_pay_data_err]"
      );
      throw custom_error;
    } else {
      animation.success();
      // 成功回跳：
      setTimeout(() => {
        redirect.successBackToNative(
            successPayData.source_scheme,
            encodeURIComponent(JSON.stringify(successPayData))
        );
      }, 1200);
      // 打点购买：
      // eco_event("paypal", successPayData);
    }
  } catch (error) {
    console.log("success_pay_data error:", error);
    error_toast_and_event(error);
  }
};

export default success_pay;
