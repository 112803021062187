import {
  interceptUrlParamsToQuery,
  transformParamsToString
} from "../../../utils/utils";
import { getEncryptData } from "../utils";
import configs from "../../common/config";
import urlUtils from "../../../utils/url_utils";
export const DEFAULT_PARAMS = {
  url: configs.baseUrl,
  timeout: 15000,
  method: "POST"
};

export const encrypt_interceptor = async (config: any) => {
  const headers: any = {
    "content-type": "application/json"
  };

  const dataBody: any = {
    method: config.method.toUpperCase(),
    path: config.url,
  };
  if (dataBody.method === "GET") {
    dataBody.query = transformParamsToString(config.params);
    const { result_url, result_query } = interceptUrlParamsToQuery(config.url);
    result_url && (dataBody.path = result_url);
    result_query && (dataBody.query += "&" + result_query);
    config.params &&
    (config.url = `${config.url}?${transformParamsToString(config.params)}`);
  } else {
    dataBody.body = config.data
  }
  const encodeURL = encodeURI(config.url);
  config.url = encodeURL;
  console.log("data",dataBody);
  const auth = urlUtils.getAttribute((window as any).__URL_DATA, "ath");
  headers.Authorization = auth;
  return {
    ...DEFAULT_PARAMS,
    data: JSON.stringify(getEncryptData(dataBody)),
    headers
  };
}
