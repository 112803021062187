import { URL_MAP_KEY } from "../config/url_map";
import { RedirectMark } from "../config/types";
import { deleteInvalidValueOfObejct } from "./utils";

const URL_UTILS: any = {
  transformObject(obj: any) {
    let newObj: any = JSON.parse(JSON.stringify(obj));
    for (let i in newObj) {
      for (let j in URL_MAP_KEY) {
        if (i === j && URL_MAP_KEY[j] !== j) {
          // key name same 替换掉缩写的字段 为 实际字段
          newObj[URL_MAP_KEY[j]] = newObj[i];
          delete newObj[i];
        }
      }
    }
    return newObj;
  },
  getUrlData(urlData: string) {
    let url = urlData.substring(urlData.indexOf("?") + 1); //获取url中"?"符后的字串
    let theRequest: any = new Object();
    let strs = url.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(
        strs[i].substring(strs[i].indexOf("=") + 1)
      );
    }
    console.log("theRequest", theRequest);
    return this.transformObject(theRequest);
  },
  getPurePrice(price: any) {
    let final_price = price.toString();
    final_price = final_price.replace("$", "");
    final_price = final_price.trim();
    return final_price;
  },
  getUrlQuery(url: string) {
    return url.substring(url.indexOf("?"));
  },
  queryString(obj: any) {
    // qs 方法
    let pushArr = [];
    if (Object.prototype.toString.call(obj) === "[object Object]") {
      for (const key in obj) {
        if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
          pushArr.push(
            `${key}=${encodeURIComponent(JSON.stringify(obj[key]))}`
          );
        } else if (key !== undefined) {
          pushArr.push(`${key}=${obj[key]}`);
        }
      }
      return pushArr.join("&");
    } else {
      throw new Error("expect object type param");
    }
  },
  // 从url_data中获取对应的值，如果值最外层没有，从user_info或者feed_data中取值
  getAttribute(data: any, attribute: string, required?: boolean) {
    let result = this.getAttributeFromData(data, attribute);
    // 如果是必须参数并且返回为undefined或者Null, 则返回空串
    if (required && !result) {
      result = "";
    }
    return result;
  },
  getAttributeFromData(data: any, attribute: string) {
    if (data.hasOwnProperty(attribute)) {
      if (
        attribute === "user_info" ||
        attribute === "feed_data" ||
        attribute === RedirectMark.PAYPAL_FORM_SUBMIT
      ) {
        // 两个字段为JSON.string类型
        return JSON.parse(data[attribute]);
      } else {
        return data[attribute];
      }
    } else {
      // 如果最外层没有，从user_info或者feed_data中取值
      const user_info = data.user_info && JSON.parse(data.user_info);
      if (user_info && user_info.hasOwnProperty(attribute)) {
        return user_info[attribute];
      }
      const feed_data = data.feed_data && JSON.parse(data.feed_data);
      if (feed_data && feed_data.hasOwnProperty(attribute)) {
        return feed_data[attribute];
      }
      return null;
    }
  },
  getDefaultParams(urlData: any) {
    const default_params = {
      platform: this.getAttribute(urlData, "platform", true),
      app_version: this.getAttribute(urlData, "app_version", true),
      master_id: this.getAttribute(urlData, "master_id", true),
      target_user_id: this.getAttribute(urlData, "user_id", true),
      offer_id: this.getAttribute(urlData, "offer_id", true),
      device_info: {
        customer_user_id: this.getAttribute(urlData, "android_id", true),
        android_id: this.getAttribute(urlData, "android_id"),
        gaid: this.getAttribute(urlData, "gaid"),
        idfv: this.getAttribute(urlData, "idfv"),
        idfa: this.getAttribute(urlData, "idfa"),
      },
      resource: this.getAttribute(urlData, "feed_data"),
      currency_code: "USD",
      pay_way: this.getAttribute(urlData, "pay_by"),
      system_version: this.getAttribute(urlData, "system_version"),
      device_model: this.getAttribute(urlData, "device_model"),
      price: this.getPurePrice(this.getAttribute(urlData, "price")),
      local_price: this.getPurePrice(
        this.getAttribute(urlData, "price") // 还是从price取值，因为currency_code写死了
      ),
      lang: this.getAttribute(urlData, "lang"),
    };
    // 默认参数中可能会有一些undefined或者null的值,递归删除:
    deleteInvalidValueOfObejct(default_params);
    return default_params;
  },
};

export default URL_UTILS;
