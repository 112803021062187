// 跳转链接中的一些标志位
export enum RedirectMark {
  STRIPE_CLIENT_ID = "stripe_client_id", // stripe跳转回来会带
  PAYPAL_CLIENT_ID = "clientId", // paypal checkout跳转回来会带
  WEB_PAY_SITE = "fromWebPaySite", // 来自web电商的支付
  SUCCESS_PAY = "/successPay", // 支付成功之后的回跳
  PAYPAL_FORM_SUBMIT = "shouldPostForm", // paypal分配button模式，提交表单
  FASTER_PAY_FORM_SUBMIT = "faster_pay_form", // faster pay提交表单
  STRIPE_FROM_KIND = "from_kind", // stripe支付成功或者取消携带
}

export enum StripeFromKindTypes {
  STRIPE_CANCEL_PAGE = "stripe_cancel_page",
  STRIPE_SUCCESS_PAGE = "stripe_success_page",
}

export enum PayByTypes {
  CARD = "card",
  PAYPAL = "paypal",
  FASTERPAY = "fasterpay",
}
