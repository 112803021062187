import { anyTypeToString, stringToData } from "./local_storage_helpers";
export class WebLocalStorageWorker {
  public localStorageSupported: boolean;
  constructor() {
    this.localStorageSupported = this.canWriteStorage();
  }

  public canWriteStorage() {
    if (!window.localStorage) {
      console.log("window下不存在localStorage");
      return false;
    }
    try {
      window.localStorage.setItem("@@", "test");
      window.localStorage.removeItem("@@");
      return true;
    } catch (e) {
      console.log("window不能使用localStorage");
      return false;
    }
  }

  public set(key: string, data: any) {
    if (this.localStorageSupported) {
      const dataStr: string = anyTypeToString(data);
      localStorage.setItem(key, dataStr);
      return this.get(key);
    }
  }

  public get(key: string) {
    if (this.localStorageSupported) {
      const data: any = localStorage.getItem(key);
      return stringToData(data);
    } else {
      return null;
    }
  }

  public has(key: string) {
    return this.localStorageSupported ? !!this.get(key) : false;
  }

  public remove(key: string) {
    this.localStorageSupported && localStorage.removeItem(key);
  }

  public clear() {
    this.localStorageSupported && localStorage.clear();
  }
}
export default new WebLocalStorageWorker();
