import config from "../js/common/config";

export const isIos = () => {
  const ua = window.navigator.userAgent;
  const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  return isiOS;
};

export const isObject = (data: any) => {
  return Object.prototype.toString.call(data) === "[object Object]";
};

export function isString(x: any) {
  return Object.prototype.toString.call(x) === "[object String]";
}

/**
 * 判断是否为JSON 字符串
 * @param str
 */
export function isJSON(str: any) {
  if (isObject(str)) {
    return true;
  } else if (!isString(str)) {
    return false;
  } else if (str === "[]") {
    return true;
  }

  str = str.replace(/\s/g, "").replace(/\n|\r/, "");

  if (/^\{(.*?)\}$/.test(str)) {
    return /"(.*?)":(.*?)/g.test(str);
  } else if (/^\[(.*?)\]$/.test(str)) {
    return str
        .replace(/^\[/, "")
        .replace(/\]$/, "")
        .replace(/},{/g, "}\n{")
        .split(/\n/)
        .map((s: any) => {
          return isJSON(s);
        })
        .reduce((prev: any, curr: any) => {
          return !!curr;
        });
  }

  return false;
}

export const isEmpty = (data: any) => {
  if (typeof data === "string") {
    // "" || "  "
    const trimStr = data.trim();
    if (trimStr === "") {
      return true;
    }
  } else if (data === null || data === undefined) {
    return true;
  } else if (Array.isArray(data)) {
    // []
    return !data.length;
  }

  return false;
};

export const deleteInvalidValueOfObejct = (delete_object: any) => {
  // 删除掉对象内不合法的值
  for (let key in delete_object) {
    if (delete_object.hasOwnProperty(key)) {
      //
      if (delete_object[key] && isObject(delete_object[key])) {
        //值是对象
        deleteInvalidValueOfObejct(delete_object[key]);
      } else {
        // 直接删除
        delete_object[key] === undefined || delete_object[key] === null
          ? delete delete_object[key]
          : void 0;
      }
    }
  }
};

export const formatNumber = (n: any) => {
  n = n.toString();
  return n[1] ? n : "0" + n;
};

export const formatTime = (number: any) => {
  const month_en = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(number);
  const month = month_en[date.getMonth()];
  const day = formatNumber(date.getDate());
  const year = date.getFullYear();
  const hour = date.getHours() > 11 ? date.getHours() - 12 : date.getHours();
  const minute = formatNumber(date.getMinutes());
  const zone = date.getHours() > 11 ? "PM" : "AM";
  return `${month} ${day}, ${year} at ${hour}:${minute} ${zone}`;
};

export const calcSubsCount = (urlData: any, product_count: any, type: any) => {
  const {
    subscription_month_period,
    subscription_week_period,
    subscription_day_period,
  } = urlData;
  const month =
    subscription_month_period && parseInt(subscription_month_period);
  const week = subscription_week_period && parseInt(subscription_week_period);
  const day = parseInt(subscription_day_period);
  const typeName = type === "like" ? "New Post" : "Day";
  if (month && month >= 12) {
    const year = month / 12;
    return `${product_count} / ${typeName} (${year} ${
      year > 1 ? "years" : "year"
    })`;
  } else if (month && month < 12) {
    return `${product_count} / ${typeName} (${month} ${
      month > 1 ? "months" : "month"
    })`;
  } else if (week) {
    return `${product_count} / ${typeName} (${week} ${
      week > 1 ? "weeks" : "week"
    })`;
  } else if (day) {
    return `${product_count} / ${typeName} (${day} ${
      day > 1 ? "days" : "day"
    })`;
  }
};

/**
 * 加载js的函数
 * @param url
 */
export const loadJs = (url: string) =>
  new Promise((resolve, reject) => {
    let script: any = document.createElement("script");

    script.type = "text/javascript";
    //IE
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState == "loaded" || script.readyState == "complete") {
          script.onreadystatechange = null;
          resolve(`loaded ${url} success`);
        }
      };
    } else {
      //其他浏览器
      script.onload = () => {
        resolve(`loaded ${url} success`);
      };

      script.onerror = () => {
        reject(`loaded ${url} failed`);
      };
    }
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  });

/**
 * PayPal Button模式的提交表单
 * @param data
 */
export const payPalButtonSubmitForm = (data: any) => {
  const { button_id, uuid } = data;
  const paypal_form: any = document.getElementById("post-form");
  paypal_form.action = config.paypalUrl;
  (document.getElementById("hosted_button") as any).value = button_id;
  (document.getElementById("custom") as any).value = uuid;
  paypal_form.submit();
};

/**
 * 将params参数转成string
 * @param params
 */

export function transformParamsToString(params: any) {
  return params
      ? Object.keys(params)
          .map(
              (k) =>
                  encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
          )
          .join("&")
      : "";
}

// 截取url后面的内容去query里面
export const interceptUrlParamsToQuery = (url: any) => {
  const index = url.indexOf("?");
  return index > -1
      ? {
        result_url: url.substring(0, index),
        result_query: url.substr(index + 1)
      }
      : {
        url
      };
};
