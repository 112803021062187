import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";
import Hex from "crypto-js/enc-hex";
import web_local_storage from "@/utils/localstorage/web_local_storage.ts";
import { UUID_TYPE } from "@/utils/localstorage/web_local_keys.ts";

let UUID = "";

const key = Utf8.parse("9vApxLk5G3PAsJrM");
const iv = Utf8.parse("FnJL7EDzjqWjcaY9");
const zvKey = "gps";
const encryptVersion = "I3";

export const encodeParams = (str) => {
  const aes = AES.encrypt(JSON.stringify(str), key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return aes.ciphertext.toString();
}

export const decodeResult = (word) => {
  const encryptedHexStr = Hex.parse(word);
  const srcs = Base64.stringify(encryptedHexStr);
  const result = AES.decrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = result.toString(Utf8);
  return decryptedStr.toString();
}

export const decodeCdnResult = (word, cipherKey, cipherIv) => {
  const AES = require("crypto-js/aes");
  const encUtf8 = require("crypto-js/enc-utf8");
  const encHex = require("crypto-js/enc-hex");
  const encBase64 = require("crypto-js/enc-base64");
  const padPkcs7 = require("crypto-js/pad-pkcs7");
  const key = encUtf8.parse(cipherKey);
  const iv = encUtf8.parse(cipherIv);
  const encryptedHexStr = encHex.parse(word);
  const srcs = encBase64.stringify(encryptedHexStr);
  const result = AES.decrypt(srcs, key, {
    iv,
    padding: padPkcs7
  })
  const decryptedStr = result.toString(encUtf8);
  return decryptedStr;
}

/**
 * 获取加密后的数据
 * @param str
 */
export const getEncryptData = (str) => {
  return {
    zv: zvKey,
    data: encryptVersion + encodeParams(str)
  };
};

// create uuid
export function creatUUid(length = 36, upper = true) {
  const uid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .slice(0, length);
  return upper ? uid.toUpperCase() : uid;
}

export const getUUid = async () => {
  if (UUID !== "") {
    return UUID;
  } else {
    const result = await web_local_storage.get(UUID_TYPE);
    if (result) {
      return result;
    } else {
      UUID = creatUUid();
      await web_local_storage.set(UUID_TYPE, UUID);
      return UUID;
    }
  }
};
