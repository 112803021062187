import { paypal_approve } from "../../../../api/service/paypal";
import { error_toast_and_event } from "../../../../../utils/error_utils";
import config from "../../../../common/config";
import { animation } from "../../../../../utils/toast_loading";
import { isSubsOffer } from "../../../../../utils/payments_utils";
import redirectUtil from "../../../../../utils/redirect_utils";

/**
 * 购买成功后的hook
 * @param urlData
 */
export const approve_hook = (urlData: any) => {
  const approve_hook_func = async (data: any, actions: any) => {
    //
    try {
      //
      console.log("approve_hook_func:", {
        data,
        actions,
      });
      const { request_url, clientId, offer_id } = urlData;
      // orderId 普通类型 subscriptionID 订阅类型
      const { orderID, subscriptionID } = data;
      // request_data
      const request_data: any = {
        pp_client_id: clientId,
        offer_id,
        product_kind: "novel"
      };
      // transaction_id在订阅模式下传subscriptionID，在消耗模式下还是传orderID
      if (isSubsOffer(urlData)) {
        subscriptionID && (request_data.transaction_id = subscriptionID);
      } else {
        orderID && (request_data.transaction_id = orderID);
      }
      // receipt的序列化
      data && (request_data.receipt = JSON.stringify(data));
      //
      const approve_res = await paypal_approve(
        request_url || config.baseUrl,
        request_data
      );
      console.log("approve_res:", approve_res);
      // 成功返回以后 弹出动画:
      animation.hide();
      animation.success();
      // 回跳回原生或者纯web
      redirectUtil.redirectAfterApprove(approve_res, urlData);
    } catch (error) {
      // 此处需要hide_loading,没有抛错到error_hooks中
      error_toast_and_event(error);
      // 请求错误，或者代码错误,隐藏Loading动画
      animation.hide();
    }
  };
  return approve_hook_func;
};
