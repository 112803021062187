import { error_toast_and_event } from "../../../../../utils/error_utils";
import { paypal_checkout_create } from "../../../../api/service/paypal";
import config from "../../../../common/config";

export const create_order_hook = (urlData: any) => {
  const create_order_hook_func = async () => {
    // create_order(urlData);
  };
  return create_order_hook_func;
};
export const create_order = async (urlData: any, client_id: string, plan_id?: string) => {
  try {
    // 动画开始:
    // animation.loading();
    // 请求所需的全部参数都在urlData中:
    const { request_url, price, offer_id } = urlData;
    // 解析参数传参：
    const request_data: any = {
      pp_client_id: client_id,
      offer_id,
      price: parseFloat(price.replace("$", "").trim()).toString(),
      product_kind: "novel"
    };
    // plan_id在订阅情况下才会有
    plan_id && (request_data.plan_id = plan_id);
    // 请求
    const create_order_res: any = await paypal_checkout_create(
        request_url || config.baseUrl,
        request_data
    );
    //
    console.log("create_order_res:", create_order_res);
    // 返回的是id
    const { id } = create_order_res;
    return id;
  } catch (error) {
    console.log("create_order_error:", error);
    error_toast_and_event(error);
    // 请求错误，或者代码错误,隐藏Loading动画
    // animation.hide();
  }
};
