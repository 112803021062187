import { error_toast_and_event } from "../../../../utils/error_utils";
import { paypal_button_create } from "../../../api/service/paypal";
import { payPalButtonSubmitForm } from "../../../../utils/utils";
import urlUtils from "../../../../utils/url_utils";
import { RedirectMark } from "../../../../config/types";

/**
 * 直接重定向回来的button的类型的支付
 * 直接对pp的form进行表单提交即可
 * @param urlData
 */
export const pay_pal_button_pay_direct = (urlData: any) => {
  try {
    //
    console.log("pay_pal_button_pay_direct:", urlData);
    const pay_pal_button_form_data = urlUtils.getAttribute(
      urlData,
      RedirectMark.PAYPAL_FORM_SUBMIT
    );
    console.log("pay_pal_button_form_data:", pay_pal_button_form_data);
    // 提交表单
    payPalButtonSubmitForm(pay_pal_button_form_data);
  } catch (error) {
    //
    error_toast_and_event(error);
  }
};

/**
 * 老button的支付方式，请求后带上参数重定向
 * @param urlData
 * @param init_result
 */
export const pay_pay_button_pay = async (urlData: any, init_result: any) => {
  try {
    // 老代码的逻辑
    let post_data: any = {
      offer_id: urlData.offer_id,
      url: init_result.url,
      button_id: init_result.button_id,
      single_pay: true,
      source_scheme: urlData.source_scheme,
      js_bundle_version: "1.5.0",
      product_kind: "novel"
    };
    // // 如果feed_data字段存在，则传给接口，否则不传这个字段
    // if (urlData.hasOwnProperty("feed_data")) {
    //   post_data.feed_data = urlData.feed_data;
    // }
    //判断是否有重定向地址：[纯web会传这个字段过来]
    if (urlData.redirect_url) {
      post_data.redirect_url = urlData.redirect_url;
    } else {
      //没有重定向的地址：通过本域名加写死路由，由本代码域名处理，加#是因为非history模式
      post_data.redirect_url =
        window.location.origin + `/#${RedirectMark.SUCCESS_PAY}/`;
    }

    const create_order_res: any = await paypal_button_create(post_data);
    console.log("create_order_res:", create_order_res, init_result);
    // 重定向的地址:
    const { url, button_id } = init_result;
    const { uuid } = create_order_res;
    //
    const query_data = {
      ppf: {
        uuid,
        button_id,
        paypal_url: url,
        result: "succ"
      }
    };
    // 重定向去页面submit form
    let baseURL = url;
    if (process.env.NODE_ENV === "development") {
      //在开发环境下，自己跳自己
      baseURL = window.location.origin;
    }
    console.log("===", `${baseURL}?${urlUtils.queryString(query_data)}`);
    window.location.href = `${baseURL}?${urlUtils.queryString(query_data)}`;
  } catch (error) {
    return Promise.reject(error);
  }
};
