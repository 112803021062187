// import eco_event from "./log_event/eco_event";
import { RedirectMark } from "../config/types";

const pathMap: any = {
  success: "wps?dj=",
  fail: "wpf?em=",
};

export default {
  successBackToNative(protocol: string, queryString: string) {
    let backUrl: string;
    if (protocol.includes("http")) {
      backUrl = `${protocol}/order_detail/${queryString}`;
    } else {
      backUrl = `${protocol}://${pathMap.success}${queryString}`;
    }

    window.location.href = backUrl;
    console.log("redirected success url is", backUrl);
  },
  failBackToNative(protocol: string, err: string) {
    const backUrl = `${protocol}://${pathMap.fail}${err}&ec=404`;
    window.location.href = backUrl;
    console.log(`redirected fail url is ${backUrl}`);
  },
  emptyBackToNative(protocol: string) {
    const backUrl = `${protocol}://`;
    window.location.href = backUrl;
    console.log(`redirected empty url is ${backUrl}`);
  },
  redirectAfterApprove(approve_res: any, urlData: any) {
    const { redirect_url, source_scheme } = urlData;
    // approve_res没有source_scheme
    approve_res.source_scheme = source_scheme;
    // 回跳的string
    const back_to_native_string = encodeURIComponent(
      JSON.stringify(approve_res)
    );

    if (redirect_url) {
      // 购买打点:
      // eco_event("paypal", approve_res);
      // 要回到纯web页面的购买:
      window.location.href = redirect_url + back_to_native_string;
    } else {
      // 此处不需要打点，在successPay会自己打点
      // 自己跳自己页面，触发/successPay/
      // 因为部分老安卓自己拦截了/successPay/ 所以通过这种方式触发安卓的拦截，相当于走了老button模式的成功回跳
      setTimeout(() => {
        window.location.href =
          window.location.origin +
          `/#${RedirectMark.SUCCESS_PAY}/` +
          back_to_native_string;
      }, 300);
    }
  },
};
