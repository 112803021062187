import $ from "umbrellajs";
import { formatTime, calcSubsCount } from "./utils";

export const formatToThousands = (num: any) => {
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
};

/**
 * 实际显示的数量的文案
 * @param init_result
 */
export const actual_effect_factory = (init_result: any) => {
  const { actual_effect, offer_data } = init_result;
  if (actual_effect) {
    return actual_effect;
  } else if (offer_data) {
    //
    const { kind } = offer_data;
    const paypal_offer =
      offer_data && offer_data.pay_data && offer_data.pay_data.paypal;
    if (kind && kind.includes("lucky_coin")) {
      // lucky coins的显示数量
      const lucky_effect = offer_data && offer_data.lucky_effect;
      return `${lucky_effect.min}~${formatToThousands(lucky_effect.max)}`;
    } else {
      return paypal_offer && paypal_offer.effect;
    }
  }
};

/**
 * 订单标题的显示
 * @param color
 */
const add_payment_detail_title = (color: string) => {
  //
  const titleEle = document.createElement("div");
  $(titleEle)
    .attr(
      "style",
      `font-size:24px;font-weight:900;color:${color};margin-bottom:30px`
    )
    .html("Payment details");
  $("#paypal-checkout").append(titleEle);
};

/**
 * 订单时间的显示
 * @param color
 */
const add_payment_detail_order_time = (color: string) => {
  //
  const orderTimeEle = document.createElement("div");
  const now = new Date().getTime();
  const time = formatTime(now);
  const orderTimeText = "Order Time: " + time;
  $(orderTimeEle)
    .attr("style", `color:${color};margin-bottom:15px`)
    .html(orderTimeText);
  $("#paypal-checkout").append(orderTimeEle);
};

/**
 * 订单的图标显示
 * @param icon_type
 * @param color
 */
const add_payment_detail_product_icon = (icon_type: string, color: string) => {
  const productIconEle = document.createElement("div");
  const productContent = `Product:<img style='height: 16px;margin-left: 5px;vertical-align: text-top' src='../../assets/img/type/${icon_type}.png'/>`;
  $(productIconEle)
    .attr("style", `color:${color};margin-bottom:15px`)
    .html(productContent);
  $("#paypal-checkout").append(productIconEle);
};

/**
 * 订单的显示数量
 * @param final_effect
 * @param color
 */
const add_payment_detail_product_numbers = (
  final_effect: string,
  color: string
) => {
  const numberEle = document.createElement("div");
  const orderTimeText = "Number: " + final_effect;
  $(numberEle)
    .attr("style", `color:${color};margin-bottom:30px`)
    .html(orderTimeText);
  $("#paypal-checkout").append(numberEle);
};

/**
 * 订单的显示的产品数量
 * @param price
 * @param color
 */
const add_payment_detail_product_price = (price: string, color: string) => {
  const priceEle = document.createElement("div");
  const priceItemText = "Price: " + price;
  $(priceEle)
    .attr(
      "style",
      `color:${color};margin-bottom:40px;font-size:20px;font-weight:700`
    )
    .html(priceItemText);
  $("#paypal-checkout").append(priceEle);
};

/**
 * checkout的button的容器
 */
const add_payment_pay_pal_button_container = () => {
  const payPalButtonEle = document.createElement("div");
  $(payPalButtonEle).attr("id", "paypal-button-container");
  $("#paypal-checkout").append(payPalButtonEle);
};

/*
 * stripe的容器
 * */
const add_payment_stripe_container = () => {
  const stripeButtonEle = document.createElement("div");
  $(stripeButtonEle).attr("id", "checkout-button");
  $(stripeButtonEle).html("Checkout");
  $("#paypal-checkout").append(stripeButtonEle);
};

/**
 * 往页面中增加支付的详情信息,title,图片，数量，价格
 * @param urlData
 * @param isStripe 是否是stripe
 */
export const add_payment_details = (
  urlData: any,
  isStripe: boolean = false
) => {
  const themeColor: string = "rgb(46, 46, 48)";
  const { product_kind, product_count, actual_effect, price } = urlData;
  // 最后显示的实际数量，如果init获取到了，就用重定向下来的actual_effect,否则用客户端的
  // product_count,但是客户端的不准确，有Bug
  let final_effect = actual_effect || product_count;

  // 生成图标的类型，实际的显示的数量：
  let icon_type = null;
  if (product_kind.includes("subs_like")) {
    icon_type = "s_l";
    final_effect = calcSubsCount(urlData, final_effect, "like");
  } else if (product_kind.includes("subs_follow")) {
    icon_type = "s_f";
    final_effect = calcSubsCount(urlData, final_effect, "follow");
  } else if (product_kind.includes("golden_follow")) {
    icon_type = "gf";
  } else if (product_kind.includes("lucky_coin")) {
    icon_type = "lc";
  } else if (product_kind.includes("coin")) {
    icon_type = "co";
  } else if (product_kind.includes("follow")) {
    icon_type = "fw";
  } else if (product_kind.includes("like")) {
    icon_type = "le";
  } else if (product_kind.includes("view")) {
    icon_type = "vw";
  } else if (product_kind.includes("comment")) {
    icon_type = "ct";
  } else if (product_kind.includes("vip")) {
    icon_type = "vp";
    final_effect =
      final_effect + (parseInt(final_effect) > 1 ? " Months" : " Month");
  }

  // add title
  add_payment_detail_title(themeColor);
  // add order time
  add_payment_detail_order_time(themeColor);
  // 添加图标：
  icon_type && add_payment_detail_product_icon(icon_type, themeColor);
  // 添加数量:
  add_payment_detail_product_numbers(final_effect, themeColor);
  // 添加价格:
  add_payment_detail_product_price(price, themeColor);
  // 最后添加PayPal checkout的按钮容器:
  isStripe
    ? add_payment_stripe_container()
    : add_payment_pay_pal_button_container();
};
