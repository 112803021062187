import { rejectError } from "./fetchCommon";
import utils from "common/utils";
import Fetch from "common/fetch";
import serviceUtils from "./util";
import { log_event } from '../log_event/log_event'

const handler = (urlData, config, isCard) => {
  return new Promise((resolve, reject) => {
    const master_id = serviceUtils.getAttribute(urlData, "master_id");
    const platform = serviceUtils.getAttribute(urlData, "platform");
    const offer_id = serviceUtils.getAttribute(urlData, "offer_id");
    const path = isCard ? "/web/v3/stripe/order/init" : "/api/v1/paypal/init";
    //请求服务器:
    Fetch.req({
      method: "POST",
      baseURL: config.baseUrl,
      url: path,
      data: {
        offer_id,
        platform,
      },
      // params: {
      //   master_id,
      //   platform,
      //   offer_id
      // },
      encode: true,
      decode: true,
      timeout: 10000,
      headers: {

      }
    }).then((data) => {
      console.log("init result", data);
      resolve(data)
    })
    .catch((error) => {
      // reject(errorMessage);
      rejectError(error, path)
      reject(error);// 抛出错误，如果产生了错误，让外层catch走button模式
    })
  })
}
export default handler;
