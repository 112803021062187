import { RedirectMark, PayByTypes, StripeFromKindTypes } from "../config/types";

/**
 * 成功支付的链接
 * @param fullUrl
 * @param urlData
 */
export const isPaySuccess = (fullUrl: string) => {
  return fullUrl.indexOf(RedirectMark.SUCCESS_PAY) > -1;
};

/**
 * 从电商跳转过来的购买方式
 * @param urlData
 */
export const isPayByEcommerce = (urlData: any) => {
  return urlData.hasOwnProperty(RedirectMark.WEB_PAY_SITE);
};

/**
 * 直接向pp发起button模式的表单请求
 * @param urlData
 */
export const isPayByPayPalButtonDirect = (urlData: any) => {
  return urlData.hasOwnProperty(RedirectMark.PAYPAL_FORM_SUBMIT);
};

/**
 * 直接向faster_pay提交表单的请求
 * @param urlData
 */
export const isPayByFasterPayDirect = (urlData: any) => {
  return urlData.hasOwnProperty(RedirectMark.FASTER_PAY_FORM_SUBMIT);
};

/**
 * 直接向pp发起checkout模式的
 * @param urlData
 */
export const isPayByPayPalCheckoutDirect = (urlData: any) => {
  return urlData.hasOwnProperty(RedirectMark.PAYPAL_CLIENT_ID);
};

/**
 * stripe支付，已经重定向过的
 * 携带stripe_client_id是已经重定向过来的
 * @param urlData
 */
export const isPayByStripeRedirect = (urlData: any) => {
  return urlData.hasOwnProperty(RedirectMark.STRIPE_CLIENT_ID);
};

/**
 * stripe支付，未重定向过的
 * 不携带paypal_client_id并且pay_by===card
 * @param urlData
 */
export const isPayByStripeStart = (urlData: any) => {
  return (
    !urlData.hasOwnProperty(RedirectMark.PAYPAL_CLIENT_ID) &&
    urlData.hasOwnProperty("pay_by") &&
    urlData.pay_by === PayByTypes.CARD
  );
};

/**
 * stripe支付成功回跳
 * from_kind === stripe_success_page
 * @param urlData
 */
export const isStripeSuccessBack = (urlData: any) => {
  return (
    urlData.hasOwnProperty(RedirectMark.STRIPE_FROM_KIND) &&
    urlData[RedirectMark.STRIPE_FROM_KIND] ===
      StripeFromKindTypes.STRIPE_SUCCESS_PAGE
  );
};

/**
 * stripe支付取消回跳
 * from_kind === stripe_cancel_page
 * @param urlData
 */
export const isStripeCancelBack = (urlData: any) => {
  return (
    urlData.hasOwnProperty(RedirectMark.STRIPE_FROM_KIND) &&
    urlData[RedirectMark.STRIPE_FROM_KIND] ===
      StripeFromKindTypes.STRIPE_CANCEL_PAGE
  );
};

/**
 * 通过默认的pp的支付方式跳转过来
 * 判断是否是paypal没有唯一标识，所以只能通过排除法
 * @param fullUrl
 * @param urlData
 */
export const isPayByPayPal = (urlData: any) => {
  return urlData.hasOwnProperty("offer_id");
};

export const isSubsOffer = (urlData: any) => {
  const subOfferKeys = [
    "subscription_day_period",
    "subscription_month_period",
    "subscription_week_period",
  ];
  const isSubOfferKeyExist = subOfferKeys.some((item) => {
    // 先判断是否有key，再判断是否有值，且值必须是number类型大于0的字符串
    return (
      urlData.hasOwnProperty(item) &&
      Boolean(urlData[item]) &&
      !Number.isNaN(Number.parseFloat(urlData[item])) &&
      Number.parseFloat(urlData[item]) > 0
    );
  });
  if (isSubOfferKeyExist) {
    return true;
  } else if (
    urlData.product_kind.includes("subs") ||
    urlData.product_kind == "vip"
  ) {
    return true;
  } else {
    return false;
  }
};
