import $ from "umbrellajs";

export const animation = {
  processing() {
    $("#loading-container").removeClass("hide").addClass("show");
    $("#loading").html(`<img src="../../assets/gif/processing.gif" />`);
  },
  success() {
    $("#loading-container").removeClass("hide").addClass("show");
    $("#loading").html(`<img src="../../assets/gif/successful.gif" />`);
  },
  loading() {
    $("#loading-container").removeClass("hide").addClass("show");
    $("#loading").html(`<img src="../../assets/gif/loading.gif" />`);
  },
  error() {
    $("#loading-container").removeClass("hide").addClass("show");
    $("#loading").html(`<img src="../../assets/gif/error.gif" />`);
  },
  hide() {
    $("#loading-container").removeClass("show").addClass("hide");
    $("#loading img").remove();
  },
};

export const toast = {
  warn(content: string) {
    $("#toast").removeClass("hide").addClass("show");
    $("#toast .toast-content").html(content);
    $("#toast .toast-icon-wrapper").html(
      `<img class="toast-icon-warn" src="../../assets/img/la_app_toast_notice.png" />`
    );
    setTimeout(() => {
      $("#toast").removeClass("show").addClass("hide");
      $("#toast .toast-content").html("");
    }, 2000);
  },
  error(content: string) {
    $("#toast").removeClass("hide").addClass("show");
    $("#toast .toast-content").html(content);
    $("#toast .toast-icon-wrapper").html(
      `<img class="toast-icon-error" src="../../assets/img/la_app_toast_false.png" />`
    );
    setTimeout(() => {
      $("#toast").removeClass("show").addClass("hide");
      $("#toast .toast-content").html("");
    }, 2000);
  },
  unexpectedError(content: string = "Oops,Unexpected error~") {
    // 意外语法错误:
    this.error(content);
  },
};
