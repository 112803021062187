import { RedirectMark } from "./types";

interface UrlMayKeyInterface {
  payby: string;
  rawData: string; // fasterpay的
  uc: string;
  is: string;
  ss: string;
  fn: string;
  av: string;
  pf: string;
  pk: string;
  ps: string; //card类型支付需要使用
  ak: string;
  un: string;
  fc: string;
  lc: string;
  vc: string;
  cc: string;
  uj: string;
  tj: string;
  oi: string;
  ru: string; // 用于纯web网页的重定向
  pc: string;
  smc?: string;
  swc?: string;
  sdc?: string;
  sv?: string; // system_version 系统版本
  dm?: string; // device_model 设备
  lg?: string;
  cui?: string;
  iv?: string;
  ia?: string;
  ae?: string;
  cyc?: string; // currency_code 货币
  lp?: string;
  ai?: string;
  gd?: string;
  ppf?: string;
  fromWebPaySite?: string;
}

/**
 * 以下为准
 */
const URL_MAP_KEY: any = {
  payby: "pay_by", // 没有缩写 支付方式
  rawData: "raw_data", // faster_pay的data
  uc: "user_coin",
  is: "image_src",
  ss: "source_scheme",
  fn: "full_name",
  av: "app_version",
  mt: "multiple_tab", // 多页面
  pf: "platform",
  pk: "product_kind",
  ps: "price", //card类型支付需要使用
  ak: "app_kind",
  un: "username",
  fc: "follow_count",
  lc: "like_count",
  vc: "view_count",
  cc: "comment_count",
  uj: "user_info",
  tj: "feed_data",
  oi: "offer_id",
  ru: "redirect_url", // 用于纯web网页的重定向
  pc: "product_count",
  smc: "subscription_month_period",
  swc: "subscription_week_period",
  sdc: "subscription_day_period",
  sv: "system_version", // system_version 系统版本
  dm: "device_model", // device_model 设备
  lg: "lang",
  cui: "customer_user_id",
  iv: "idfv",
  ia: "idfa",
  ae: "actual_effect", // 实际显示的数量的文案，lucky_coins可能显示(100-1000)
  cyc: "currency_code", // currency_code 货币
  lp: "local_price",
  ai: "android_id",
  gd: "gaid",
  rtu: "request_url", //重定向回来带上的checkout的请求地址
  ppf: RedirectMark.PAYPAL_FORM_SUBMIT, // 重定向回来直接向pp提交表单
  fpf: RedirectMark.FASTER_PAY_FORM_SUBMIT, //重定向回来直接向faster_pay提交表单
  ppcid: RedirectMark.PAYPAL_CLIENT_ID, // stripe重定向回来带上的checkout的client_id
  pppid: "pay_pal_plan_id", // 重定向回来带上的checkout的plan_id
  fromWebPaySite: RedirectMark.WEB_PAY_SITE, //从电商直接跳过来的
  button_id: "button_id", // 从电商带过来的
  custom: "custom", // 从电商带过来的
  sci: RedirectMark.STRIPE_CLIENT_ID, // stripe client id
  fk: RedirectMark.STRIPE_FROM_KIND,
  el: "email",
};

export { URL_MAP_KEY };
