import { error_toast_and_event } from "../../../../../utils/error_utils";
import {
  toast,
  animation
} from "../../../../../utils/toast_loading";
// import { pay_pay_checkout_order_error } from "../../../../api/pp_checkout/pp_checkout";
// import config from "../../../../../config";

/**
 * 错误钩子的执行
 * @param pp_error
 */
export const error_hook = (urlData: any) => {
  //
  const { request_url } = urlData;
  const error_hook_func = async (pp_error: any) => {
    try {
      //
      const { message } = pp_error;
      console.log("error_hook_func message:", message);
      // 隐藏Loading动画 （在create或者approve中正常走了，但是返回的id给pp产生了错误）
      animation.hide();

      if (typeof message === "string") {
        // toast 此处需要toast的,不能用error_toast_and_event，catch的error信息不全
        // 需要判断是否是来自于pp校验的error
        // toast.error(message);
        // 可能请求失败，这里也会触发，会覆盖之前的toast

        // 上报后台,用于上报给后台pp的校验错误
        // await pay_pay_checkout_order_error(
        //   request_url || config.BASE_URL,
        //   message
        // );
      }
    } catch (error) {
      //
      error_toast_and_event(error);
    }
  };
  return error_hook_func;
};

export const cancle_hook = async (pp_error: any) => {
  //
  try {
    //
    console.log("cancle_hook:", pp_error);
    // 隐藏Loading动画,在create或者approve中产生的
    animation.hide();
    // 弹toast
    toast.warn("User cancelled");
  } catch (error) {
    //
  }
};
