import urlUtils from "../../../utils/url_utils";
import { paypal_init } from "../../api/service/paypal";
import { pay_pal_checkout_pay } from "./checkout/pay_pal_checkout";
import { pay_pay_button_pay } from "./button/pay_pal_button";
import { error_toast_and_event } from "../../../utils/error_utils";

const pay_paypal = async (urlData: any) => {
    try {
        // 先请求后台init接口，获取pp的支付方式:1.button 2. checkout
        const offer_id = urlUtils.getAttribute(urlData, "offer_id");
        const init_result: any = await paypal_init(offer_id);
        console.log("pay_pay_init res:", init_result);
        // 判断支付方式:
        const { type } = init_result;
        if (type === "checkout") {
            // checkout 模式
            await pay_pal_checkout_pay(init_result, urlData);
        } else {
            // button模式
            await pay_pay_button_pay(urlData, init_result);
        }
    } catch (error) {
        // 错误处理有两种，一种是请求的错误，一种是代码的错误
        error_toast_and_event(error);
    }
};

export default pay_paypal;
