// index.html需要插入的script,所有html需要插入的script都必须与html同名，如果想让页面引入css，必须通过模块导入
// 如果想实现热替换(不刷新整个页面，局部更新)，在下方加上 module.hot.accept()即可
import pay_paypal from "../js/channels/paypal/index";
import * as paymentsUtils from "../utils/payments_utils";
import {
  pay_pal_button_pay_direct
} from "./channels/paypal/button/pay_pal_button";
import {
  pay_pal_checkout_pay_direct
} from "./channels/paypal/checkout/pay_pal_checkout";
import success_pay from "../js/channels/success_pay";

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept();
}

import "../css/index.scss";
// import urlUtils from "common/urlUtils";
import urlUtils from "../utils/url_utils";
import utils from "common/utils";
import Handler from "./handler/index";
import { confirmOrder, cancelOrder } from "./handler/checkout/cardCheckout";
/**
 * 初始化：转化URl>Data,如果为card支付显示card,如果为paypal则postForm
 * @constructor
 */
class Initial {
  constructor() {
    this.urlData = null;
    this.fullUrl = window.location.href;
    this._init();
  }

  _process() {
    if (paymentsUtils.isPaySuccess(this.fullUrl)) {
      success_pay();
    } else if (paymentsUtils.isPayByPayPalButtonDirect(this.urlData)) {
      pay_pal_button_pay_direct(this.urlData);
    } else if (paymentsUtils.isPayByPayPalCheckoutDirect(this.urlData)) {
      pay_pal_checkout_pay_direct(this.urlData);
    } else if (paymentsUtils.isPayByPayPal(this.urlData)) {
      pay_paypal(this.urlData);
    }
  }

  _init() {
    // // 加载成功去掉loading
    // utils.$animation.hide();
    //将url转换为Object
    this.urlData = urlUtils.getUrlData(window.location.href);
    window.__URL_DATA = this.urlData;
    console.log("this.urlData", this.urlData);

    this._process();
  }
}
new Initial();
