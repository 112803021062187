import request from "../base/base";
import {
  INIT,
  APPROVE,
  BUTTON_CREATE,
  CHECKOUT_CREATE
} from "../consts/paypal"

export const paypal_init = async (
  offer_id
) => {
  try {
    const res = await request.post(INIT, {
      offer_id
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const paypal_approve = async (
  base_url,
  data
) => {
  try {
    const res = await request.post(APPROVE, data, {
      baseURL: base_url
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const paypal_checkout_create = async (
  base_url,
  data
) => {
  try {
    console.log("paypal_checkout_create", data);
    const res = await request.post(CHECKOUT_CREATE, data, {
      baseURL: base_url
    });
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const paypal_button_create = async (data) => {
  try {
    const res = await request.post(BUTTON_CREATE, data);
    return JSON.parse(res);
  } catch (e) {
    return Promise.reject(e);
  }
}
