/**
 * 1.错误的打点
 * 2.错误的toast
 */
// import error_event from "../../utils/log_event/error_event";
import { toast } from "./toast_loading";

export const error_toast_and_event = (error: any) => {
  const { message, config, custom_name } = error;
  console.log("error_toast_and_event:", {
    message,
    config,
    custom_name,
  });
  if (config) {
    // 请求类型的报错，在拦截器中已经做了处理
  } else {
    // 语法类型的错误，toast并且打点:
    toast.error(message);
    // 打点:
    if (custom_name) {
      // error_event(message, custom_name);
    } else {
      // error_event(message ? message : error);
    }
  }
};

export const custom_name_error = (message: string, custom_name: string) => {
  const custom_error: any = new Error(message);
  custom_error.custom_name = custom_name;
  return custom_error;
};
