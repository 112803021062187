import { decodeResult } from "../utils"
import {isObject} from "../../../utils/utils";

export const decrypt_interceptor = async (response: any) => {
  try {
    const result = isObject(response.data) ? response.data : JSON.parse(response.data);
    console.log("result===",response, result);
    // 如果token失效
    if (result && result.meta && result.meta.code === 200) {
      return decodeResult(result.data);
    } else {
      return Promise.reject(result.meta);
    }
  } catch (error) {
    console.log("error==", error, response);
    return Promise.reject(error);
  }
}
